<template>
    <div class="abs-center px-2 wd-xxl mt-5">
        <!-- START card-->
        <div class="text-center mb-3"><img src="img/layout/user-image.svg" width="64px"/></div>
        <div class="card card-default" :class="loading ? 'whirl' : ''">
            <div class="card-body">
                <p class="text-center py-2 text-uppercase">{{$t('common.view.public.reset_password.heading')}}</p>
                <form @submit.prevent="validateBeforeSubmit('recover')" data-vv-scope="recover">
                    <p class="text-center">{{$t('common.view.public.reset_password.description')}}</p>
                    <div class="form-group">
                        <label class="text-muted" for="signupInputPassword">{{$t('common.view.public.reset_password.password.label')}}</label>
                        <div class="input-group with-focus">
                            <input ref="password" :class="{'form-control border-right-0':true, 'is-invalid': errors.has('recover.password')}" id="signupInputPassword" v-model="recover.password" v-validate="'required'" type="password" name="password" :placeholder="$t('common.view.public.reset_password.password.placeholder')"/>
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fad fa-lock"></em>
                                </span>
                            </div>
                            <span v-show="errors.has('recover.password')" class="invalid-feedback">{{ errors.first('recover.password') }}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="text-muted" for="signupInputRePasswordConfirmation">{{$t('common.view.public.reset_password.password_confirm.label')}}</label>
                        <div class="input-group with-focus">
                            <input :class="{'form-control border-right-0':true, 'is-invalid': errors.has('recover.password_confirmation')}" id="signupInputRePasswordConfirmation" v-model="recover.password_confirmation" v-validate="'required|confirmed:password'" type="password" name="password_confirmation" :placeholder="$t('common.view.public.reset_password.password_confirm.placeholder')"/>
                            <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fad fa-lock"></em>
                                </span>
                            </div>
                            <span v-show="errors.has('recover.password_confirmation')" class="invalid-feedback">{{ errors.first('recover.password_confirmation') }}</span>
                        </div>
                    </div>
                    <button class="btn btn-danger btn-block" type="submit">{{$t('common.view.public.reset_password.submit')}}</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                loading: false,
                recover: {
                    password: '',
                    password_confirmation: '',
                    email: '',
                    token: ''
                }
            }
        },
        mounted() {
            this.recover.token = this.$route.params.token;
            this.recover.email = this.$route.params.email;
        },
        methods: {
            validateBeforeSubmit(scope) {
                this.$validator.validateAll(scope).then((result) => {
                    if (result) {
                        this.loading = true;
                        this.$api.post('auth/reset-password', this.recover).then(response => {
                          this.$swal.fire('Success', 'Login with your new password.', 'success');
                          this.$router.push({name: 'Login'});
                        }).catch(error => {
                            if (error.status === 400) {
                                this.$swal.fire('Something went wrong', 'Your reset is invalid or expired, try again.', 'error');
                                this.$router.push({name: 'Login'});
                            } else if (error.status === 422) {
                                this.$setErrorsFromResponse(error);
                            }
                        })
                    }
                });
            }
        }
    }
</script>
